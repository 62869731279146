var $_CONFIG = $_CONFIG || {};

$_CONFIG.gsapVersion = '3';
$_CONFIG.gsap3Plugins = [
    'Draggable.min.js',
    'InertiaPlugin.min.js'
];

$_CONFIG.sport = true;
$_CONFIG.sportVersion = 3;
$_CONFIG.sportFixedBottom = 72;
$_CONFIG.sportId = 'fezbet';
$_CONFIG.sportWidgetSkinName = 'fezbet';

$_CONFIG.useSvgSprite = false;

$_CONFIG.scripts = $_CONFIG.scripts || [];

$_CONFIG.anjouanId = "88e832fa-fddd-451b-8b42-1423b4c30b2f";
$_CONFIG.googleAnalytics = { appId: 'UA-160252894-1 ', trackAs: 'fezbet.com '}

$_CONFIG.symplify = true;

$_CONFIG.domain = 'https://fezbet.devv2.space';
$_CONFIG.siteDomain = 'https://m.fezbet.com';
$_CONFIG.cdn = 'https://joxi.imgsrcdata.com';

$_CONFIG.pwa = true;
$_CONFIG.appName = 'Fezbet';
$_CONFIG.themeColor = '#2D2D32';

$_CONFIG.mobile = true;

$_CONFIG.siteName = 'fezbet';

$_CONFIG.places = {
    banner: {
      australian_open: `'season_sport_tournament'`,
      wimbledon: `'tennis_banner'`,
    },
    slider: {
        home1: `'main_slider'`
    },
    promo: {
        registration: `'registration'`,
        home3: `'home-promo'`
    },
    icons: {
        footer1: `'footer-payments'`,
        footer2: `'footer-partners'`
    }
};

export { $_CONFIG };
